import React from 'react'
import { BaseCSS } from 'styled-bootstrap-grid';
import { CookiesProvider } from 'react-cookie';

import SEO from '../components/Seo';
import Menu from '../components/Menu';
import Cookies from '../components/Cookies';

const NotFoundPage = ({location = {href: 'test'}}) => (
  <CookiesProvider>
    <BaseCSS />
      <Cookies page={location.href}/>
      <SEO title="404: Not found" />
      <Menu />
      <div style={{margin: '100px 50px'}}>
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
      </div>
  </CookiesProvider>
);

export default NotFoundPage
